/* App.css */
.app {
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.logo img {
  height: 80px; /* Ajuste conforme necessário */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: #333;
}

.navbar a:hover {
  text-decoration: underline;
}

main {
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para a página de Perguntas */
.renderQ {
  font-family: Arial, sans-serif;
  margin: 20px;
}
.renderQ h1 {
  text-align: center;
}
form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.question-block {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #f9f9f9;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
select {
  width: calc(100% - 20px);
  padding: 8px;
  margin: 5px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  margin-top: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

button:hover {
  background-color: #45a049;
}

button[type="button"] {
  background-color: #f44336;
}

button[type="button"]:hover {
  background-color: #e53935;
}

button[type="submit"] {
  background-color: #2196f3;
}

button[type="submit"]:hover {
  background-color: #1976d2;
}

.option-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.option-row input[type="text"] {
  flex-grow: 1; /* Para que o input cresça e ocupe o espaço disponível */
  margin-right: 10px; /* Espaço entre o input e o botão */
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Adicione estas regras de estilo ao App.css para remover o sublinhado dos links */
a {
  text-decoration: none;
  color: inherit; /* Para manter a cor do link igual ao texto */
}

a:hover {
  text-decoration: none;/* Opcional: adicionar sublinhado ao passar o mouse */
}

/* Outros estilos */
button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

li button {
  margin-left: 10px;
}

